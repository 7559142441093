import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"];

  notifyDependants(e) {
    if (this.element.matches("[data-qa=\"kyc-trust-toggle\"]")) {
      const confirmationMessage = this.inputTarget.checked
        ? "Are you sure?"
        : "Are you sure?\nDisabling KYC Trust will also disable Import CSV - Payment Requests.";

      if (confirm(confirmationMessage)) {
        this.fireToggleOn(this.identifier, this.inputTarget, e);
      } else {
        this.inputTarget.checked = !this.inputTarget.checked;
      }
    } else {
      this.fireToggleOn(this.identifier, this.inputTarget, e);
    }
  }

  fireToggleOn(identifier, inputTarget, e) {
    Rails.fire(
      inputTarget,
      `${identifier}:${inputTarget.id}:${e.type}`,
      { checked: inputTarget.checked }
    );
  }

  onChangeDependency(e) {
    const {checked} = e.detail
    if (!checked) {
      this.inputTarget.checked = false
    }
    this.inputTarget.disabled = !checked
  }

  onClick(e) {
    e.preventDefault()
    Rails.fire(this.element, "submit")
  }

  onSuccess() {
    if (this.hasInputTarget) {
      this.inputTarget.checked ^= 1
      Rails.fire(this.inputTarget, "change")
    }
  }
}
