import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "fullLogo",
    "name",
    "successMsg"
  ]

  connect() {
    Split.iav_selected_bank_controller = this
    this.successMsgTarget.style.display = "none"
  }

  setup(bank_data) {
    this.fullLogoTarget.style.display = "none"
    this.nameTarget.style.display = "none"
    if (bank_data.fullLogoUrl ) {
      this.fullLogoTarget.src = bank_data.fullLogoUrl
      this.fullLogoTarget.style.display = "inline-block"
    } else {
      this.nameTarget.innerText = bank_data.fullName
      this.nameTarget.style.display = "inline-block"
    }
    this.element.style.backgroundColor = bank_data.backgroundColor || null
  }

  displaySuccess(message) {
    this.successMsgTarget.innerText = message
    $(this.successMsgTarget).fadeIn()
  }

  show() {
    $(this.element).fadeIn()
  }

  hide() {
    this.element.style.display = "none"
  }
}
