import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "errorMsg",
    "form",
    "browserClientId",
    "providerName",
    "institutionId",
    "bankConnectionId",
    "loginId",
    "securityCode",
    "securityCodeHint",
    "password",
    "submitBtn",
    "progressText",
  ]

  connect() {
    Split.iav_login_controller = this

    let controller = this
    Split.cable.subscriptions.create({
      channel: "BankConnectionChannel", client: controller.browserClientIdTarget.value
    }, {
      received(data) {
        Split.start_loading_btn($(controller.submitBtnTarget));
        if (data.progress) { Split.set_loading_btn_progress($(controller.submitBtnTarget), data.progress) }
        if (data.status !== "in-progress") {
          clearTimeout(controller.iav_status_timeout)
          clearTimeout(controller.iav_response_timeout)
        }

        switch (data.status) {
        case "success":
          Split.reset_loading_btn($(controller.submitBtnTarget))
          Split.iav_root_controller.loginSucceeded(data.bank_connection_id, data.message)
          controller.hide()
          break

        case "in-progress":
          controller.responseTimer()
          if (data.message === controller.previous_iav_message) { return }
          controller.previous_iav_message = data.message
          controller.progressTextTarget.innerText = data.message
          controller.statusFeedbackTimer()
          break

        case "failed":
          controller.displayError(data.message)
          break

        case "error":
          Split.iav_root_controller.serviceUnavailable(data.message)
          controller.displayError(data.message)
        }
      }
    })
  }

  setup(bank_data) {
    this.providerNameTarget.value = bank_data.providerName
    this.institutionIdTarget.value = bank_data.id
    this.loginIdTarget.placeholder = bank_data.loginCaption
    this.passwordTarget.placeholder = bank_data.passwordCaption

    this.submitBtnTarget.style.backgroundColor = bank_data.primaryColor || null
    this.submitBtnTarget.style.color = bank_data.btnTextColor || null
    this.hideElement(this.errorMsgTarget)

    if (bank_data.securityCodeCaption) {
      this.securityCodeTarget.placeholder = bank_data.securityCodeCaption
      this.securityCodeHintTarget.innerText = bank_data.securityCodeHint || null
      this.securityCodeTarget.disabled = false

      this.showElement(this.securityCodeTarget)
      this.showElement(this.securityCodeHintTarget)

    } else {
      this.securityCodeTarget.disabled = true

      this.hideElement(this.securityCodeTarget)
      this.hideElement(this.securityCodeHintTarget)
    }

    if (bank_data.bankConnectionId) {
      this.bankConnectionIdTarget.value = bank_data.bankConnectionId
    } else {
      this.bankConnectionIdTarget.disabled = true
    }

    if (bank_data.bankConnectionId && bank_data.usernameForUpdate == "false") {
      this.loginIdTarget.disabled = true
      this.loginIdTarget.required = false
      this.hideElement(this.loginIdTarget)
    }
  }

  show() {
    this.formTarget.reset()
    this.showElement(this.element)
  }

  hide() {
    this.hideElement(this.element)
  }

  submit(e) {
    this.hideElement(this.errorMsgTarget)
    let required_inputs = Array.from(e.currentTarget.querySelectorAll("input[required]"))
    required_inputs.forEach(e => e.parentNode.classList.remove("has-error"))

    let error_inputs = required_inputs.filter(input => !input.value)
    if (error_inputs.length) {
      e.preventDefault()
      error_inputs.forEach(e => e.parentNode.classList.add("has-error"))
    } else {
      Split.start_loading_btn($(this.submitBtnTarget));
      Split.set_loading_btn_progress($(this.submitBtnTarget), 20)
      this.responseTimer()
      this.statusFeedbackTimer()
    }
  }

  displayError(message) {
    this.errorMsgTarget.innerText = message
    this.showElement(this.errorMsgTarget)
    Split.reset_loading_btn($(this.submitBtnTarget))
  }

  responseTimer() {
    let controller = this
    clearTimeout(controller.iav_response_timeout);
    controller.iav_response_timeout = setTimeout(function () {
      controller.displayError("Service currently unavailable, please try again later.")
      Split.iav_root_controller.serviceUnavailable("The instant account verification is currently unavailable.")
      clearTimeout(controller.iav_status_timeout);
    }
    , 60000)
  }

  statusFeedbackTimer(message = "Still working...") {
    let controller = this
    clearTimeout(controller.iav_status_timeout);
    controller.iav_status_timeout = setTimeout(function () {
      controller.progressTextTarget.innerText = message
      controller.statusFeedbackTimer("Sorry it's taking so long...")
    }
    , 30000)
  }

  hideElement(element) {
    element.style.display = "none"
  }

  showElement(element) {
    element.style.display = "block"
  }
}
