document.addEventListener("DOMContentLoaded", () => {
  const timeoutElement = document.querySelector("meta[name='session-timeout-seconds']")
  if (timeoutElement === null) { return }

  const timeoutValue = timeoutElement.getAttribute("content")
  if (timeoutValue === null) { return }

  const timeoutSeconds = parseInt(timeoutValue, 10)
  setTimeout(() => { window.location.href = "/" }, timeoutSeconds * 1000)
})
