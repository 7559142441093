import { Controller } from "@hotwired/stimulus";

class TeamMembers extends Controller {
  static targets = ["template", "list"];

  index = 0;

  initialize() {
    this.index = Number.parseInt(this.data.get("lastIndex") ?? "0", 10);
  }

  invite(event) {
    event.preventDefault();
    this.index++;
    const content = this.templateTarget.innerHTML.replace(
      /__num__/g,
      this.index.toString(10),
    );
    this.listTarget.insertAdjacentHTML("afterbegin", content);
  }
}

export default TeamMembers;
