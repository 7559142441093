// https://clipboardjs.com/
// https://github.com/zenorocha/clipboard.js

import ClipboardJS from "clipboard";

document.addEventListener(
  "DOMContentLoaded",
  () => {
    const onSuccess = (evt) => {
      evt.clearSelection();
      showTooltip(evt.trigger, "Copied!");
    };
    const onError = (evt) => {
      showTooltip(evt.trigger, fallbackMessage(evt.action));
    };

    const clipboard = new ClipboardJS(".copy-button");
    clipboard.on("success", onSuccess);
    clipboard.on("error", onError);

    document.querySelectorAll(".modal .copy-button").forEach(el => {
      const modal = $(el).closest(".modal")[0];
      const clipboard = new ClipboardJS(el, {container: modal});
      clipboard.on("success", onSuccess);
      clipboard.on("error", onError);
    })
  },
);

// NOTE: We currently rely on Bootstrap 3.4.x which is loaded using the Asset Pipeline.
// Manually inject an element that matches Bootstrap's requirements for styling.
function showTooltip(element, content) {
  const template = `<div class="tooltip right fade in" style="top: -2px; left: ${element.clientWidth + 2}px;">
                      <div class="tooltip-arrow"></div>
                      <div class="tooltip-inner">${content}</div>
                    </div>`;

  element.setAttribute("style", "position: relative");
  element.insertAdjacentHTML("afterbegin", template);

  // Automatically hide the tooltip after 1.5 seconds.
  setTimeout(function () {
    element.getElementsByClassName("tooltip")[0].remove();
  }, 1500);
}

function fallbackMessage(action) {
  const actionKey = (action === "cut" ? "X" : "C");

  if (/iPhone|iPad/i.test(navigator.userAgent)) {
    return "Not supported :(";
  } else if (/Mac/i.test(navigator.userAgent)) {
    return `Press ⌘-${actionKey} to ${action}`;
  } else {
    return `Press Ctrl-${actionKey} to ${action}`;
  }
}
