import "footable-v3";

const footableCb = () => {
  $(".split-footable").footable({
    breakpoints: {
      lg: 1200,
      md: 992,
      sm: 768,
      xl: 1400,
      xs: 480,
    },
    cascade: true,
    useParentWidth: true,
  });
};

document.addEventListener("DOMContentLoaded", footableCb);
document.addEventListener("initFootable", footableCb);
