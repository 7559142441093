import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "squareLogo",
    "details",
    "change"
  ]

  connect() {
    Split.iav_selected_account_controller = this
  }

  show(selected_account, multiple_accounts, bank_data) {
    if (bank_data.squareLogoUrl) {
      this.squareLogoTarget.src = bank_data.squareLogoUrl
      this.squareLogoTarget.style.display = "inline"
    } else {
      this.squareLogoTarget.style.display = "none"
    }
    this.element.style.backgroundColor = bank_data.backgroundColor || null
    this.detailsTarget.style.color = bank_data.textColor || null
    this.detailsTarget.innerHTML = selected_account.innerHTML
    if (multiple_accounts) {
      this.changeTarget.style.display = "block"
    } else {
      this.changeTarget.style.display = "none"
    }
    this.element.style.display = "block"
  }

  hide() {
    this.element.style.display = "none"
  }

  change(e) {
    e.preventDefault()
    Split.iav_root_controller.changeAccount()
  }
}
