import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    Split.iav_root_controller = this
  }

  bankSelected(bank_data) {
    this.iav_selected_bank_data = bank_data
    Split.iav_selected_bank_controller.setup(bank_data)
    Split.iav_login_controller.setup(bank_data)
    Split.iav_confidence_controller.show()
    Split.iav_launch_controller.open()
  }

  confidenceConfirmed() {
    Split.iav_selected_bank_controller.show()
    Split.iav_login_controller.show()
  }

  loginSucceeded(bank_connection_id, account_list_html) {
    if (Split.iav_launch_controller.loginSuccessful) {
      Split.iav_launch_controller.loginSuccessful(bank_connection_id)
    } else {
      Split.iav_account_selection_controller.show(account_list_html, this.iav_selected_bank_data)
    }
  }

  accountConfirmed(selected_account, multiple_accounts) {
    Split.iav_parent_fieldset_controller.insertAccountData(
      selected_account.dataset.connectionId,
      selected_account.dataset.accountId
    )
    Split.iav_selected_account_controller.show(
      selected_account,
      multiple_accounts,
      this.iav_selected_bank_data
    )
    Split.iav_bank_selection_controller.hide()
    Split.iav_launch_controller.close()
  }

  changeAccount() {
    Split.iav_parent_fieldset_controller.removeAccountData()
    Split.iav_launch_controller.open(true)
  }

  cancelLogin(e) {
    e.preventDefault()
    this.reset()
  }

  serviceUnavailable(message) {
    if (Split.iav_parent_fieldset_controller && Split.iav_parent_fieldset_controller.hasManualNavTabTarget) {
      Split.iav_parent_fieldset_controller.iavUnavailable(message)
      this.reset()
    }
  }

  reset() {
    Split.iav_launch_controller.close()
    Split.iav_bank_selection_controller.show()
    Split.iav_selected_bank_controller.hide()
    Split.iav_account_selection_controller.hide()
    if (Split.iav_parent_fieldset_controller) {
      Split.iav_selected_account_controller.hide()
      Split.iav_parent_fieldset_controller.removeAccountData()
    }
  }
}
