import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    Split.iav_confidence_controller = this
  }

  confirm(e) {
    e.preventDefault()
    Split.iav_root_controller.confidenceConfirmed()
    this.hide()
  }

  show() {
    this.element.style.display = "block"
  }

  hide() {
    this.element.style.display = "none"
  }
}
