import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "iavPanel",
    "cancelLink",
    "successLink"
  ]

  connect() {
    Split.iav_launch_controller = this
    if (this.hasSuccessLinkTarget) {
      this.hideElement(this.successLinkTarget)
    }
  }

  open() {
    let bank_data = this.iavPanelTarget.dataset
    Split.iav_root_controller.iav_selected_bank_data = bank_data
    Split.iav_selected_bank_controller.setup(bank_data)
    Split.iav_login_controller.setup(bank_data)
    this.showElement(this.iavPanelTarget)
  }

  loginSuccessful() {
    Split.iav_selected_bank_controller.displaySuccess(
      "Connection successfully authorised, thank you."
    )
    if (this.hasCancelLinkTarget) {
      this.hideElement(this.cancelLinkTarget)
    }
    if (this.hasSuccessLinkTarget) {
      this.showElement(this.successLinkTarget)
    }
  }

  hideElement(element) {
    element.style.display = "none"
  }

  showElement(element) {
    element.style.display = "block"
  }
}
