import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "keyInput", "iavProvider" ]

  connect() {
    Split.iav_provider_form_controller = this
    this.handleKeyFieldVisibility(this.iavProviderTarget.value)
  }

  select(e) {
    let target = e.currentTarget
    this.handleKeyFieldVisibility(target.value)
  }

  handleKeyFieldVisibility(provider) {
    const display_rule = provider === "basiq" ? "block" : "none"

    this.keyInputTarget.style.display = display_rule
  }
}
