import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["details", "changeTypes", "dataTypes"]

  connect() {
    this.updateDetails();
  }

  optionChanged(event) {
    if (event.target.classList.contains("active")) {
      return;
    }

    event.target.classList.add("active");

    let parent_node = event.target.closest(".checkbox-group");
    for (let child_node of parent_node.querySelectorAll(".brdt-change-option")) {
      if (child_node != event.target) {
        child_node.classList.remove("active");
      }
    }
    this.updateDetails();
  }

  updateDetails() {
    let details_target = this.detailsTarget;
    let data_type = this.selectedDataType();
    let change_type = this.selectedChangeType();

    let query_url = this.detailsTarget.dataset["url"] + "?" + new URLSearchParams({
      data_type: data_type,
      change_type: change_type,
    });

    Rails.ajax({
      type: "get",
      url: query_url,
      success: function (data) {
        details_target.innerHTML = data.html;
      },
      error: function (data) {
        console.error(data);
      }
    });
  }

  selectedDataType() {
    return this.dataTypesTarget.querySelector("button.active").id;
  }

  selectedChangeType() {
    return this.changeTypesTarget.querySelector("button.active").id;
  }
}
