import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "bankConnectionId",
    "bankConnectionAccountId",
    "manualNavTab",
    "branchCode",
    "accountNumber",
    "unavailable",
    "unavailableError"
  ]

  connect() {
    Split.iav_parent_fieldset_controller = this
  }

  insertAccountData(bank_connection_id, bank_connection_account_id) {
    this.bankConnectionIdTarget.value = bank_connection_id
    this.bankConnectionAccountIdTarget.value = bank_connection_account_id
    if (this.hasManualNavTabTarget) {
      this.manualNavTabTarget.style.pointerEvents = "none"
      this.branchCodeTarget.setAttribute("disabled", "disabled")
      this.accountNumberTarget.setAttribute("disabled", "disabled")
    }
  }

  removeAccountData() {
    this.bankConnectionIdTarget.value = null
    this.bankConnectionAccountIdTarget.value = null
    if (this.hasManualNavTabTarget) {
      this.manualNavTabTarget.style.pointerEvents = "auto"
      this.branchCodeTarget.removeAttribute("disabled")
      this.accountNumberTarget.removeAttribute("disabled")
    }
  }

  iavUnavailable(message) {
    if (!this.hasManualNavTabTarget) { return }

    this.unavailableTarget.style.display = "block"
    this.unavailableErrorTarget.innerHTML = message
    $(this.manualNavTabTarget).find("a").tab("show")
  }
}
