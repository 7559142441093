import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "container",
    "iavPanel",
    "submit"
  ]

  connect() {
    Split.iav_launch_controller = this
  }

  open() {
    this.submitTarget.style.pointerEvents = "none"
    $(this.submitTarget).fadeTo(100, 0.2)
    this.containerTarget.classList.add("iav-active")
    $(this.containerTarget).fadeTo(100, 0)
    this.iavPanelTarget.style.top = this.containerTarget.offsetTop + "px"
    $(this.iavPanelTarget).fadeIn()
  }

  close() {
    this.submitTarget.style.pointerEvents = "auto"
    $(this.submitTarget).fadeTo(100, 1)
    this.containerTarget.classList.remove("iav-active")
    $(this.containerTarget).fadeTo(100, 1)
    $(this.iavPanelTarget).fadeOut()
  }
}
