import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "referenceField",
    "surnameField",
    "merchantField",
    "longReferenceField",
    "longReferenceCopyText",
    "confirmationModal",
    "submitButton",
    "modalShowButton",
    "chargeMerchantText",
    "chargeMerchantToggle"
  ]

  connect(){
    this.buildLongReference()
    this.setChargeMerchantText()
  }

  buildLongReference(){
    var values = [
      this.merchantFieldTarget.value,
      this.surnameFieldTarget.value,
      this.referenceFieldTarget.value
    ]
    var fullText = values.join("-")
    this.longReferenceFieldTarget.textContent = fullText
    this.longReferenceCopyTextTarget.dataset.clipboardText = fullText
  }

  setChargeMerchantText(){
    if (this.chargeMerchantToggleTarget.checked){
      this.chargeMerchantTextTarget.textContent = "The merchant WILL be charged for this cancellation"
    }else{
      this.chargeMerchantTextTarget.textContent = "The merchant will NOT be charged for this cancellation"
    }
  }

  submit(event){
    if(this.confirmationModalTarget.classList.contains("in")){
      this.submitButtonTarget.disabled = true;
    }else{
      event.preventDefault();
      this.modalShowButtonTarget.click()
    }
  }
}
