import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "link"
  ]

  connect() {
    Split.iav_launch_controller = this
  }

  startVerification(e) {
    e.preventDefault()
    $("#iav-bank-selection-modal").modal()
  }

  loginSuccessful(bank_connection_id) {
    this.close()
    let link_data = this.linkTarget.dataset
    $(`<form action='${link_data.formPath}' method='POST'/>`)
      .append($(`<input type='hidden' name='bank_connection_id' value='${bank_connection_id}'>`))
      .append($(`<input type='hidden' name='authenticity_token' value='${link_data.csrf}'>`))
      .appendTo($(document.body))
      .submit();
  }

  open() {
    $("#iav-bank-selection-modal").modal("hide")
    $("#iav-modal").modal()
  }

  close() {
    $("#iav-modal").modal("hide")
  }
}
