import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dependant"]

  change(e) {
    this.toggleDependants(e.target.value)
  }

  toggleDependants(parentValue) {
    this.dependantTargets.forEach(dependant => {
      const match = parentValue === dependant.dataset.parentValue
      dependant.style.display = match ? "revert" : "none"
    });
  }
}
