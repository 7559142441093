import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "container",
    "manualNavTab",
    "branchCode",
    "accountNumber"
  ]

  connect() {
    Split.iav_launch_controller = this
  }

  open(edit_mode=false) {
    if (edit_mode) {
      $("#iav-modal .modal-footer").hide()
    } else {
      $("#iav-modal .modal-footer").show()
    }
    $("#iav-modal").modal()
  }

  close() {
    $("#iav-modal").modal("hide")
  }
}
