import * as Ladda from "ladda";

document.addEventListener(
  "DOMContentLoaded" ,
  () => {
    Ladda.bind( ".ladda-button",{
      callback: (instance) => {
        let progress = 0;
        const interval = setInterval(() => {
          progress = Math.min(progress + Math.random() * 0.1, 1);
          instance.setProgress(progress);

          if(progress === 1) {
            instance.stop();
            clearInterval(interval);
          }
        }, 200);
      }
    });
  }
);
