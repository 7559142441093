import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "list",
    "confirm",
    "infoText"
  ]

  connect() {
    Split.iav_account_selection_controller = this
  }

  show(account_list_html, bank_data) {
    this.element.innerHTML = account_list_html
    this.infoTextTarget.style.color = bank_data.textColor || null
    this.confirmTarget.style.color = bank_data.btnTextColor || null
    this.confirmTarget.style.backgroundColor = bank_data.primaryColor || null
    $(this.element).fadeIn()
  }

  hide() {
    this.element.style.display = "none"
  }

  select(e) {
    e.preventDefault()
    let clicked_li = e.currentTarget
    if (clicked_li.classList.contains("not-selected")) { return }

    if (clicked_li.classList.contains("selected")) {
      this.deselectAccount()
    } else {
      this.listTarget.classList.add("has-selected")
      this.listTarget
        .querySelectorAll("li")
        .forEach(e => e.classList.add("not-selected"))
      clicked_li.classList.remove("not-selected")
      clicked_li.classList.add("selected")
      clicked_li.style.borderColor = window.getComputedStyle(this.confirmTarget).backgroundColor
      this.confirmTarget.removeAttribute("disabled")
    }
  }

  deselectAccount() {
    this.listTarget.classList.remove("has-selected")
    this.listTarget
      .querySelectorAll("li")
      .forEach(e => {
        e.classList.remove("not-selected", "selected")
        e.style.borderColor = null
      })
    this.confirmTarget.setAttribute("disabled", "disabled")
  }

  confirm(e) {
    e.preventDefault()
    let selected_account = this.listTarget.querySelector("li.selected")
    if (!selected_account) { return }

    Split.iav_root_controller.accountConfirmed(
      selected_account,
      this.listTarget.querySelectorAll("li").length > 1
    )
    this.deselectAccount()
  }
}
