import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "container",
    "iavPanel",
    "form",
    "bankConnectionId"
  ]
  connect() {
    Split.iav_launch_controller = this
  }

  loginSuccessful(bank_connection_id) {
    this.bankConnectionIdTarget.value = bank_connection_id
    this.formTarget.submit()
  }

  open() {
    this.containerTarget.classList.add("iav-active")
    $(this.containerTarget).fadeTo(100, 0)
    this.iavPanelTarget.style.top = this.containerTarget.offsetTop + "px"
    $(this.iavPanelTarget).fadeIn()
  }

  close() {
    $(this.iavPanelTarget).fadeOut(50, () => {
      this.containerTarget.classList.remove("iav-active")
      $(this.containerTarget).fadeTo(100, 1)
    })
  }
}
