import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    Split.iav_bank_selection_controller = this
  }

  select(e) {
    e.preventDefault()
    let bank_data
    let target = e.currentTarget
    if (e.type == "change") {
      bank_data = target.options[target.selectedIndex].dataset
      target.value = null
    } else {
      bank_data = target.dataset
    }
    Split.iav_root_controller.bankSelected(bank_data)
  }

  show() {
    this.element.style.display = "block"
  }

  hide() {
    this.element.style.display = "none"
  }
}
